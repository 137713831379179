import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
window.$ = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';
import './lib/TileLayerGrayscale';


$(document).foundation();


/* --------------------------------------------
	Navigation
---------------------------------------------*/
$('#mobilnav-btn').on('click', function(){
	var navRight, btnRight;
	
	if($(this).children('i').hasClass('fa-bars')){
		$(this).children('i').removeClass('fa-bars').addClass('fa-times');
		
		navRight = 0;
		btnRight = 230;
	} else {
		$(this).children('i').removeClass('fa-times').addClass('fa-bars');
		
		navRight = -230;
		btnRight = 0;
	}
	
	$('.is-drilldown').animate({
		right: navRight+'px'
	});
	
	$('#mobilnav-btn').animate({
		right: (btnRight+15)+'px'
	});
	
	$('body').animate({
		'margin-left': '-'+btnRight+'px'
	});
	
	return false;
});



/* --------------------------------------------
 	Totop-Button
---------------------------------------------*/
$('#totop').on('click', function(){
	$('html, body').animate({ 
        scrollTop: 0
    });
	
	return false;
});


/* --------------------------------------------
	Kontaktbutton
---------------------------------------------*/
$('#contact-btn').hover(function(){
	$('#contact-content').show();
}, function(){
	$('#contact-content').hide();
});

$('#contact-content').hover(function(){
	$('#contact-content').show();
}, function(){
	$('#contact-content').hide();
});



/* --------------------------------------------
	Startseite: Kopfbild
---------------------------------------------*/
$(window).on('scroll', function(){
	if($(this).scrollTop() > 0){
		$('.mainpageheader').css({'z-index':'1'});
		$('.outerheader').css({'background-color':'#73B72B', 'margin-top':'-55px'});
		$('#mainnav').css({'margin-top':'-25px'});
		$('#mainheader #topnav').css({'margin-top':'-50px;'});
		$('#logo img').css({'max-height':'60px'});
		$('#start--header--new').css({'z-index':'0'});
	} else {
		$('.mainpageheader').css({'z-index':'1'});
		$('.outerheader').css({'background-color':'unset', 'margin-top':'0'});
		$('#mainnav').css({'margin-top':''});
		$('#mainheader #topnav').css({'margin-top':''});
		$('#logo img').css({'max-height':''});
		$('#start--header--new').css({'z-index':'1'});
	}
});



/* --------------------------------------------
	Form: Checkbox
---------------------------------------------*/
$('input[type="checkbox"]').on('change', function(){
	if($(this).prop('checked')){
		$(this).parent().addClass('active-check');
	} else {
		$(this).parent().removeClass('active-check');
	}
});



/* -----------------------------------------------
	Form-Validation
-------------------------------------------------*/
$('form [type="submit"]').on('click', function(){
	var returnVal = true;

	$(this).parents('form').find('[required]').each(function(){
		if($(this).val() == '' || $(this).val() == ' ' || $(this).val() == '0'){
			returnVal = false;
			$(this).addClass('error');
		}
		
		if($(this).attr('type') === 'checkbox' && !$(this).prop('checked')){
			returnVal = false;
			$(this).parent().addClass('error');
		}
	});
	
	var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	$(this).parents('form').find('[type="email"]').each(function(){
		if($(this).val() != '' && !regex.test($(this).val())){
			returnVal = false;
			$(this).addClass('error');
		}
	}); 
	
	return returnVal;
});


/* -----------------------------------------------
	MAP
-------------------------------------------------*/
var maplat = (typeof mapmarkerlat !== 'undefined') ? mapmarkerlat:52.207850;
var maplng = (typeof mapmarkerlng !== 'undefined') ? mapmarkerlng:8.804030;

var markericon = L.divIcon({
	html: '<i class="fas fa-map-marker-alt"></i>',
	iconAnchor: [18,48],
});

$(function(){
	if($('#map').length > 0){
		var mymap = L.map('map', {
			center: [maplat, maplng],
			zoom: 14,
			scrollWheelZoom: false
		});
		
		L.marker([maplat, maplng], {icon:markericon}).addTo(mymap);
		
		L.tileLayer.grayscale('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
			attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors</a>'
		}).addTo(mymap);
	}
});


/* -----------------------------------------------
	DIALOG
-------------------------------------------------*/
function closeDialog(div){
	jQuery(div).animate({
	    top: '-800px'
	}, 400, function(){
	    jQuery('.dialog').css({'display':'none'});
	});
}

jQuery(function(){
	jQuery('.dialog-button').on('click', function(){
	    var dialog = jQuery(this).attr('href');
	    var content = dialog + ' .dialog-content';
	    jQuery(dialog).css({'display':'block'});
	    var height = (jQuery(window).height()/2) - (jQuery(content).height()/2);
	    jQuery(content).animate({
	        top: height + 'px'
	    }, 500);
	
	    return false;
	});
	
	jQuery('.grey-bg').on('click', function(){
	    var dialog = '#' + jQuery(this).parent().attr('id') + ' .dialog-content';
	    closeDialog(dialog);
	    return false;
	});
	
	jQuery('.close').on('click', function(){
	    var dialog = '#' + jQuery(this).parent().parent().attr('id') + ' .dialog-content';
	    closeDialog(dialog);
	    return false;
	});
});


/* -----------------------------------------------
	SEARCH
-------------------------------------------------*/
var req = false;

function searchPopUp(value){
    if(value.length != 0 && $(window).width() > 1023){
    	if(req){
			req.abort();
		} 
    	
    	req = jQuery.post('/assets/php/post.php', {'type': 'search', 'value': value}, function(data){
            $('#search-output').html(data);
        });
    } else {
	   $('#search-output ul').hide();
    }
}

$(function(){
	$('.search-btn').on('click', function(){
		$('.search-form input[type="text"]').focus();
	});
	
	$('#search input[name="s"]').on('keyup', function(){
		searchPopUp($('#search input[name="s"]').val());
	});
});


/* -----------------------------------------------
	LANDINGPAGE-KOPF & Startseite
-------------------------------------------------*/
$(window).on('load', function(){
	if($('#lp-header').length > 0 && $(window).width() > 1023){
		var blueHeight = $('#lp-header .blue-transparent-bg').height();
		var greenHeight = $('#lp-header .green-transparent-bg').height();
		
		if(blueHeight > greenHeight){
			$('#lp-header .green-transparent-bg').height(blueHeight);
		} else {
			$('#lp-header .blue-transparent-bg').height(greenHeight);
		}
	}
	
	if($('#lp-header-img').length > 0 && $(window).width() > 1023){
		var blueHeight = $('#lp-header-img .blue-transparent-bg').height();
		var greenHeight = $('#lp-header-img .green-transparent-bg').height();
		
		if(blueHeight > greenHeight){
			$('#lp-header-img .green-transparent-bg').height(blueHeight);
		} else {
			$('#lp-header-img .blue-transparent-bg').height(greenHeight);
		}
	}
	
	if($('#start-header').length > 0 && $(window).width() > 1023){
		var blueHeight = $('#start-header .blue-transparent-bg').height();
		var greenHeight = $('#start-header .green-transparent-bg').height();

		if(blueHeight > greenHeight){
			$('#start-header .green-transparent-bg').height(blueHeight);
		} else {
			$('#start-header .blue-transparent-bg').height(greenHeight);
		}
	}
});



/* --------------------------------------------
	SLIDER
---------------------------------------------*/
var cur_slider_elem = [];
var disabled = false;


function getForward(slider){
	var id = parseInt(slider.attr('id').replace('slider', ''));
	var curr_elem = cur_slider_elem[id];

	var next_elem = (curr_elem.next().length) ? curr_elem.next():slider.children('.slider-elem-wrapper').children('.slider-elem').first();
	
	next_elem.css({'left': '200%'}).animate({
		'left':'0'
	});
	
	curr_elem.animate({
		'left':'-200%'
	}, function(){
		curr_elem.css({'left': '200%'});
		cur_slider_elem[id] = next_elem;
		disabled = false;
	});
}


function getBackward(slider){
	var id = parseInt(slider.attr('id').replace('slider', ''));
	var curr_elem = cur_slider_elem[id];
	
	var prev_elem = (curr_elem.prev().length) ? curr_elem.prev():slider.children('.slider-elem-wrapper').children('.slider-elem').last();
	
	prev_elem.css({'left': '-200%'}).animate({
		'left':'0'
	});
	
	curr_elem.animate({
		'left':'200%'
	}, function(){
		cur_slider_elem[id] = prev_elem;
		disabled = false;
	});
}



$(window).on('load', function(){
	var i = 0;
	
	$('.slider').each(function(){
		var slider = $(this);
		slider.attr('id', 'slider'+i);
		
		cur_slider_elem.push(slider.children('.slider-elem-wrapper').children('.slider-elem').first());
		setInterval(function(){ getForward(slider); }, 8000);
		
		i++;
	});
});

$('.left-arrow').on('click', function(){
	if(!disabled){
		disabled = true;
		getBackward($(this).parent());
	}
	return false;
});

$('.right-arrow').on('click', function(){
	if(!disabled){
		disabled = true;
		getForward($(this).parent());
	}
	return false;
});


/*Abfrage Browsergröße für News */
$(window).resize(function() {
if($(window).innerWidth() <= 1023){
	$('.news .the--start"').removeClass('start-news');
}else {
     $('.news .the--start"').addClass('start-news');
    }
});


$('.start-video-frame').on('click', function(){
	$('.start-video-frame').css('display', 'none');
	var video = $(".start-video-frame iframe").attr("src");
	$(".start-video-frame iframe").attr("src","");
	$(".start-video-frame iframe").attr("src",video);
});


$('.video-button').on('click', function(){
	$('.start-video-frame').css('display', 'block');
});